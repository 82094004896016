<template>
  <div class="boxx">
    <div class="left">
      <div class="leftTop">
        <el-select v-model="value" placeholder="请选择" @change="langChange">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button
          @click="runCodeBtn"
          type="primary"
          icon="el-icon-s-promotion"
          :loading="loadingS"
        >
          运行
        </el-button>
      </div>
      <codemirror
        v-model="item"
        :options="cmOption"
        class="code-mirror"
        @ready="onCmReady3"
        @focus="onCmFocus"
        @input="onCmCodeChange"
        ref="myCmGenerate"
      ></codemirror>
    </div>
    <div class="right" v-loading="loadingS">
      <p v-html="runcodeTxt"></p>
    </div>
  </div>
</template>
<script>
import { runCode } from "@/api/order.js";
import { codemirror } from "vue-codemirror";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/mode/python/python";
import "codemirror/mode/clike/clike";
import "codemirror/mode/htmlmixed/htmlmixed";
import "../utils/seting.js";
export default {
  props: ["cmTheme", "cmMode", "cmIndentUnit", "autoFormatJson"],
  data() {
    return {
      item: "",
      runcodeTxt: "",
      loadingS: false,
      options: [
        {
          value: "clike",
          label: "java",
        },
        {
          value: "python",
          label: "python",
        },
        {
          value: "htmlmixed",
          label: "html/css",
        },
        // {
        //   value: "javascript",
        //   label: "javascript",
        // },
      ],
      value: "clike",
      cmOption: {
        tabSize: 2, // tab
        styleActiveLine: true, // 高亮选中行
        lineNumbers: true, // 显示行号
        styleSelectedText: false, //line选择是是否高亮
        matchBrackets: true, //括号匹配
        line: true,
        lint: true, // 检查格式
        foldGutter: true, // 可将对象折叠，与下面的gutters一起使用
        gutters: [
          "CodeMirror-lint-markers",
          "CodeMirror-linenumbers",
          "CodeMirror-foldgutter",
        ],
        highlightSelectionMatches: {
          minChars: 2,
          style: "matchhighlight",
          showToken: true,
        },
        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
        // highlightSelectionMatches: { showToken: /\w/, annotateScrollbar: true }, // 可以启用该选项来突出显示当前选中的内容的所有实例
        mode: "python", // 代码格式
        // hint.js options
        hintOptions: {
          // 当匹配只有一项的时候是否自动补全
          completeSingle: true,
        },
        // 快捷键 可提供三种模式 sublime、emacs、vim
        keyMap: "sublime",
        showCursorWhenSelecting: true,
        theme: "monokai", // 主题
        extraKeys: { Ctrl: "autocomplete" }, // 可以用于为编辑器指定额外的键绑定，以及keyMap定义的键绑定
      },
    };
  },
  mounted() {
    this.item = `public class Main {
	public static void main(String[] args) {
		System.out.println(" 我在课堂猫学JAVA");
		System.out.println();
		// 练习一下循环的使用
		for (int i=1; i<=10; i++) {
      if(i==1){
        System.out.printf(" 我爱课堂猫 %d 次 <br/> ", i);
      }else{
				System.out.printf("我爱课堂猫 %d 次 <br/> ", i);
      }
		}
		System.out.printf("课堂猫送我一颗小心心：");
		// 绘制一个心形图案
		float x, y;
		for (y = (float)1.5; y > -1.2; y -= 0.1) {
			for (x = (float)-1.5; x < 1.7; x += 0.04) {
				float a = x * x + y * y - (float)0.9;
				if ((a * a * a - x * x * y * y * y) <= 0.0) {
					System.out.print("1");
				} else {
					System.out.print(" ");
				}
			}
			System.out.println();
		}
	}
}`;
  },
  components: { codemirror },
  methods: {
    onCmReady3() {
      this.$refs.myCmGenerate.codemirror.setSize("50vw", "91vh");
    },
    onCmFocus(instance, event) {
      console.log(instance);
    },
    onCmCodeChange(instance, obj) {
      this.item = instance;
      console.log(instance);
    },
    langChange() {
      this.item = "";
      this.runcodeTxt = "";
      this.cmOption.mode = this.value;
      console.log(this.value);
      if (this.value == "clike") {
        this.item = `public class Main {
	public static void main(String[] args) {
		System.out.println(" 我在课堂猫学JAVA");
		System.out.println();
		// 练习一下循环的使用
		for (int i=1; i<=10; i++) {
      if(i==1){
        System.out.printf(" 我爱课堂猫 %d 次 <br/> ", i);
      }else{
				System.out.printf("我爱课堂猫 %d 次 <br/> ", i);
      }
		}
		System.out.printf("课堂猫送我一颗小心心：");
		// 绘制一个心形图案
		float x, y;
		for (y = (float)1.5; y > -1.2; y -= 0.1) {
			for (x = (float)-1.5; x < 1.7; x += 0.04) {
				float a = x * x + y * y - (float)0.9;
				if ((a * a * a - x * x * y * y * y) <= 0.0) {
					System.out.print("1");
				} else {
					System.out.print(" ");
				}
			}
			System.out.println();
		}
	}
}`;
      }
      if (this.value == "htmlmixed") {
        this.item = `<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <title>Document</title>
  <style>
      p {
          padding: 10px 20px;
          background: #ccc;
          color: red;
      }
  </style>
</head>
<body>
  <p>欢迎使用课堂猫</p >
</body>
</html>`;
      }
      if (this.value == "python") {
        this.item = `#!/usr/bin/python
print('Hello, World!')`;
      }
    },

    runCodeBtn() {
      if (this.value == "clike" || this.value == "python") {
        this.loadingS = true;
        let params = {
          code: this.item,
          type: this.value == "clike" ? "java" : this.value,
        };
        runCode({ ...params })
          .then((res) => {
            this.runcodeTxt = res.data;
            this.loadingS = false;
          })
          .catch(() => {
            this.loadingS = false;
          });
      }
      if (this.value == "htmlmixed") {
        this.runcodeTxt = this.item;
      }
      //   if (this.value == "javascript") {
      //     try {
      //       const val = new Function(this.item);
      //       let eleResult = val(); // 这里必须调用val(),不然不会执行
      //       if (!eleResult) {
      //         eleResult = eval(this.item);
      //         this.runcodeTxt = eleResult;
      //       }
      //     } catch (err) {
      //       this.runcodeTxt = err;
      //     }
      //   }
    },
  },
};
</script>
<style></style>
<style lang="scss" scoped>
.boxx {
  width: 100vw;
  height: 100vh;
  background: #ebebeb;
  display: flex;
  padding: 10px;
  box-sizing: border-box;
  .left {
    .leftTop {
      background: #fff;
      padding: 10px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .right {
    flex: 1;
    height: 100%;
    overflow: auto;
    background: #fff;
    margin-left: 10px;
    padding: 10px;
    box-sizing: border-box;
  }
}
.code-mirror {
  font-size: 13px;
  line-height: 150%;
  text-align: left;
}
</style>
